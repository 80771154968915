<template>
  <form coach-detail-class-edit @submit.prevent="">
    <validation-observer ref="validator">
      <div class="button-group">
        <basic-button theme="white" @click="onCancel">취소하기</basic-button>
        <basic-button theme="blue" @click="onSubmit" type="submit">저장하기</basic-button>
      </div>
      <section-container class="text-group">
        <heading>
          <template #prepend>
            <svg-timer />
          </template>
          <template #title>수업 가능 시간</template>
        </heading>
        <validation-provider rules="required|length:10,1000" name="lessonTime" v-slot="{errors}">
          <memo-text-area v-model="lessonTime" :has-error="!!errors.length" :max-length="1000" :min-length="10" theme="base-alt" />
          <validation-errors :errors="errors" />
        </validation-provider>
      </section-container>
      <section-container class="text-group">
        <heading>
          <template #prepend>
            <svg-recommend />
          </template>
          <template #title>이런 분들께 추천합니다</template>
        </heading>
        <validation-provider rules="required|length:10,1000" name="lessonRecommendation" v-slot="{errors}">
          <memo-text-area v-model="recommendation" :has-error="!!errors.length" :max-length="1000" :min-length="10" theme="base-alt" />
          <validation-errors :errors="errors" />
        </validation-provider>
      </section-container>
    </validation-observer>
    <section-container class="lesson-item-group">
      <heading>
        <template #title>수업 내용</template>
        <template #sub>최소 1개/최대 5개</template>
      </heading>
      <coach-info-banner theme="grey" class="mobile-drag-help">
        꾹 눌러서 순서를 변경할 수 있습니다.<br /> 리스트의 첫 번째에 놓이면 대표 수업으로 지정합니다.
      </coach-info-banner>
      <!-- validation이 제대로 작동하기 위해서 별도의 컴포넌트로 분리하지 않음 -->
      <draggable v-model="lessonProducts" ghost-class="be-replaced" handle=".drag-icon" draggable=".draggable-lesson-items" @start="onDragStart" @end="onDragEnd" :class="{dragging}">
        <transition-group name="fade">
          <validation-observer ref="lessonValidator" v-for="(lp) in lessonProducts" :key="lp.lessonProductId" :data-lesson-product-id="lp.lessonProductId" tag="div" class="draggable-lesson-items" v-slot="{invalid,dirty}">
            <drawer :value="openLessonProductId === lp.lessonProductId" @input="(v) => setOpenLessonProductId(lp.lessonProductId, v)" theme="base-no-top-margin">
              <template #cover>
                <div class="lesson-item-cover" :data-error="invalid" :data-modified="dirty">
                  <img src="/img/coaching/icon-list-drag.svg" class="drag-icon" />
                  <h3 class="lesson-item-name" v-show="!invalid">{{ lp.name }}</h3>
                  <h3 class="lesson-item-name" v-show="invalid">내용을 확인해 주세요</h3>
                  <div class="badge-lesson-representative-sm" v-show="lp.isRepresentative">대표</div>
                </div>
              </template>
              <template #content>
                <div class="lesson-item-content">
                  <div class="lesson-item-head">
                    <div class="badge-lesson-representative" v-show="lp.isRepresentative">
                      <svg-check />
                      대표수업
                    </div>
                    <basic-button theme="grey" @click="() => onLessonRemove(lp.lessonProductId)" class="btn-lesson-remove" :disabled="lessonCountInsufficient" size="sm">
                      <svg-delete />
                      삭제
                    </basic-button>
                  </div>
                  <validation-provider rules="required|lengthMax:20" name="lessonName" tag="label" v-slot="{errors, passed}">
                    <span class="form-item-name">수업 제목</span>
                    <text-input-alt v-model="lp.name" :errors="errors" placeholder="수업 제목 입력(최대 20자)">
                      <template #error>
                        <validation-errors :errors="errors" :passed="passed" />
                      </template>
                    </text-input-alt>
                  </validation-provider>

                  <div class="numeric-group">
                    <validation-provider rules="required" name="lessonTimeValue" tag="label" v-slot="{errors, passed}">
                      <span class="form-item-name inline">소요시간</span>
                      <text-input-alt type="number" v-model="lp.time.value" :errors="errors" min="1" theme="base-align-center">
                        <template #error>
                          <validation-errors :errors="errors" :passed="passed" />
                        </template>
                      </text-input-alt>
                      <span>분</span>
                    </validation-provider>
                    <validation-provider rules="required" name="lessonPrice" tag="label" v-slot="{errors, passed}">
                      <span class="form-item-name inline">포인트</span>
                      <text-input-alt type="number" v-model="lp.price" :errors="errors" min="0" theme="base-align-center">
                        <template #error>
                          <validation-errors :errors="errors" :passed="passed" />
                        </template>
                      </text-input-alt>
                      <span>UP</span>
                    </validation-provider>
                  </div>
                  <div class="description-group">
                    <div class="description-group-title">
                      <span class="form-item-name">수업 설명</span>
                      <basic-button size="sm" theme="grey" @click="() => onDescriptionAdd(lp.lessonProductId)" class="btn-lesson-desc-add" :disabled="lp.description.length >= maxLessonDesc">
                        <svg-add />
                        추가하기
                      </basic-button>
                    </div>
                    <validation-provider rules="required|lengthMax:50" name="lessonDescription" tag="label" v-slot="{errors, passed}" v-for="(desc, i) in lp.description" :key="i" :vid="`${i}`">
                      <text-input-alt v-model="lp.description[i]" :errors="errors" placeholder="수업 설명을 입력하세요.">
                        <template #error>
                          <validation-errors :errors="errors" :passed="passed" />
                        </template>
                      </text-input-alt>
                      <basic-button size="sm" theme="grey" @click="() => onDescriptionRemove(lp.lessonProductId, i)" class="btn-desc-remove" :disabled="lp.description.length <= minLessonDesc">
                        <svg-minus-gray />
                      </basic-button>
                    </validation-provider>
                  </div>
                </div>
              </template>
            </drawer>
          </validation-observer>
        </transition-group>
      </draggable>
      <basic-button theme="white" @click="onLessonAdd" class="btn-lesson-add" :disabled="lessonCountExceed">
        <svg-plus />
      </basic-button>
    </section-container>
  </form>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import { nanoid } from 'nanoid';
import SvgCheck from '@shared/graphics/svg-check.vue';
import { getter } from 'shared/utils/storeUtils';
import BasicButton from '@/views/components/common/button/BasicButton.vue';
import Heading from '@/views/components/common/text/Heading.vue';
import SectionContainer from '@/views/components/coaching/SectionContainer.vue';
import MemoTextArea from '@/views/components/common/input/MemoTextArea.vue';
import Draggable from '@/views/components/coaching/draggable';
import TextInputAlt from '@/views/components/coaching/TextInputAlt.vue';
import Drawer from '@/views/components/coaching/Drawer.vue';
import ConfirmModal from '@/views/components/common/modal/SmallConfirmModal.vue';
import CoachInfoBanner from '@/views/components/coaching/CoachInfoBanner.vue';
import { SvgTimer, SvgRecommend } from '@/views/graphics/LazyLoadings';
import ripple from '@/directive/ripple';
// 나중에 lazyLoading에 모두 정리할 것
import SvgMinusGray from '@/views/graphics/svg-minus-gray.vue';
import SvgDelete from '@/views/graphics/svg-delete.vue';
import SvgAdd from '@/views/graphics/svg-add.vue';
import SvgPlus from '@/views/graphics/svg-plus.vue';
import floatPopIn from '@/mixins/floatPopIn';
import FloatEditAreaClass from '@/views/components/coaching/coach-detail/FloatEditAreaClass.vue';

export default {
  name: 'CoachDetailClassEdit',
  lexicon: 'coaching',
  mixins: [floatPopIn()],
  directives: { ripple },
  components: {
    CoachInfoBanner,
    SvgAdd,
    SvgDelete,
    SvgMinusGray,
    TextInputAlt,
    SectionContainer,
    Heading,
    BasicButton,
    SvgTimer,
    SvgRecommend,
    MemoTextArea,
    Draggable,
    Drawer,
    SvgCheck,
    SvgPlus,
  },
  props: {
    coachInfo: {
      type: Object,
      default: () => {
      },
    },
  },
  data: () => ({
    newCoachInfo: /** @type{CoachDetail} */ {},
    maxLesson: 5,
    openLessonProductId: '',
    dragging: false, // 현재 드래그 하고 있는지 여부: 포인터 변경을 위해 사용
    maxLessonDesc: 10,
    minLessonDesc: 1,
  }),
  computed: {
    myCoachId: getter('auth', 'myCoachId'),
    defaultLesson() {
      return {
        description: {
          recommendation: '',
          lessonTime: '',
        },
        lessonProducts: [
          this.makeNewLessonProduct({ name: '리플레이 코칭', description: ['피드백 받을 게임의 리플레이를 준비해 주세요.', '코치님이 해당 리플레이를 분석합니다.', '분석 내용을 바탕으로 개선 사항을 코칭합니다.'] }),
          this.makeNewLessonProduct({ name: '화면 공유로 실시간 코칭', description: ['먼저 화면 공유를 시작 후, 게임을 플레이 합니다.', '게임 상황에 맞춰 실시간으로 플레이 방향을 코칭해 드립니다.', '게임이 종료된 후, 리플레이를 통해 간단히 한번 더 점검해 드립니다.'] }),
          this.makeNewLessonProduct({ name: '사용자 설정 게임에서 코치님과 만납니다.', description: ['사용자 설정 게임에서 코치님과 만납니다.', '인게임 1:1 라인전 상황에서 코칭해 드립니다.', '게임이 종료된 후, 플레이를 간단히 한번 더 점검해 드립니다.'] }),
        ],
      };
    },
    lessonCountExceed() {
      return this?.lessonProducts?.length >= this.maxLesson;
    },
    lessonCountInsufficient() {
      return this?.lessonProducts?.length === 1;
    },
    lessonTime: {
      get() {
        return this.newCoachInfo?.lesson?.description?.lessonTime ?? '';
      },
      set(v) {
        this.$set(this.newCoachInfo?.lesson?.description, 'lessonTime', v);
      },
    },
    recommendation: {
      get() {
        return this.newCoachInfo?.lesson?.description?.recommendation ?? '';
      },
      set(v) {
        this.$set(this.newCoachInfo?.lesson?.description, 'recommendation', v);
      },
    },
    lessonProducts: {
      get() {
        const lessonProducts = /** @type{CoachLessonProduct[]} */ this.newCoachInfo?.lesson?.lessonProducts ?? [];
        return lessonProducts?.length >= 1 ? lessonProducts : this.defaultLesson.lessonProducts;
      },
      set(v) {
        v.forEach(v => v.isRepresentative = false);
        if (v[0]) v[0].isRepresentative = true;
        this.$set(this.newCoachInfo?.lesson, 'lessonProducts', v);
      },
    },
    coachId() {
      return this.coachInfo?.coachId ?? '';
    },
  },
  beforeMount() {
    this.newCoachInfo = _cloneDeep(this.coachInfo);
  },
  methods: {
    setOpenLessonProductId(lessonProductId, value) {
      // 이미 다른 수업이 열려있을 경우 해당 수업을 닫고 .3초 대기후 다른 수업을 연다.
      if (value && this.openLessonProductId !== '') {
        if (this._drawerTimer) clearTimeout(this._drawerTimer);
        this.openLessonProductId = '';
        this._drawerTimer = setTimeout(() => {
          this.openLessonProductId = lessonProductId;
        }, 500);
        return;
      }
      this.openLessonProductId = value ? lessonProductId : '';
    },
    makeNewLessonProduct({ name = '', description = ['', '', ''] } = {}) {
      return /** @type{CoachLessonProduct} */ {
        name,
        time: { unit: 'MINUTES', value: 1 },
        price: 0,
        lessonProductId: `new-${nanoid()}`,
        isRepresentative: false,
        isHide: false,
        description,
      };
    },
    onCancel() {
      this.$router.push({ name: 'CoachDetailClass', params: { tab: 'class' } });
    },
    async onSubmit() {
      this.openLessonProductId = '';
      const valid = await this.$refs.validator.validate();
      let lessonValid = true;
      if (this.$refs.lessonValidator) {
        lessonValid = (await Promise.all(this.$refs.lessonValidator.map(v => v.validate()))).every(v => v);
      }
      const allValid = valid && lessonValid;
      if (!allValid) {
        this.$toast('_.modifyLessonIntroduction.validationFail', { type: 'fail' });
        return;
      }
      const data = _cloneDeep(this.newCoachInfo.lesson);
      data.lessonProducts = data.lessonProducts.map(lp => ({ ...lp, lessonProductId: lp.lessonProductId.includes('new-') ? null : lp.lessonProductId }));
      await this.$services.coaching.modifyLessonIntroduction(this.coachId, data);
      this.$router.push({ name: 'CoachDetailClass', params: { tab: 'class' } });
    },
    async onLessonRemove(lessonProductId) {
      await this.$modal(ConfirmModal, { title: '작성하신 내용을 삭제할까요?' });
      this.lessonProducts = this.lessonProducts.filter(lp => lp.lessonProductId !== lessonProductId);
    },
    onLessonAdd() {
      const newLessonProduct = this.makeNewLessonProduct();
      this.lessonProducts = [...this.lessonProducts, newLessonProduct];
    },
    onDescriptionRemove(lessonProductId, descIndex) {
      const lessonProductIndex = this.lessonProducts.findIndex(lp => lp.lessonProductId === lessonProductId);
      if (lessonProductIndex === -1) return;
      this.$delete(this.lessonProducts[lessonProductIndex]?.description, descIndex);
    },
    onDescriptionAdd(lessonProductId) {
      const lessonProductIndex = this.lessonProducts.findIndex(lp => lp.lessonProductId === lessonProductId);
      if (lessonProductIndex === -1) return;
      const description = this.lessonProducts[lessonProductIndex]?.description;
      this.$set(this.lessonProducts[lessonProductIndex], 'description', ['', ...description]);
    },
    onDragStart(e) {
      this.dragging = true;
      e.target.classList.add('dragging');
    },
    onDragEnd(e) {
      this.dragging = false;
      e.target.classList.remove('dragging');
    },
    getFloatPopInOptions() {
      return ({
        componentFloat: FloatEditAreaClass,
        optionsFloat: { onCancel: this.onCancel.bind(this), onSave: this.onSubmit.bind(this), editMode: true },
      });
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[coach-detail-class-edit] {
  [drawer] {border-bottom: solid 1px #eaeaea;.bgc(@c-white)}
  [text-input-alt] {.mb(8)}
  .lesson-item-cover {.flex;.h(64);.flex-ai(center);}
  .drag-icon {.wh(32);cursor: grab;.mr(8)}
  .lesson-item-content {.p(20);.bgc(#f6f6f6);}
  .form-item-name {.fs(14);.c(@c-black-primary);.mb(12);.block;font-weight: 500;}
  .form-item-name.inline {display: unset;.mb(0);}
  .numeric-group {.flex;}
  .numeric-group > label {flex: 1;.flex;.flex-ai(center);}
  .numeric-group [text-input-alt] {.w(120);.ml(12);.mr(12);}
  .numeric-group [text-input-alt] ~ span {.c(#444);font-weight: 400;}
  .numeric-group > label > span {.h(48)}
  .lesson-item-name {.fs(16);font-weight: 500;flex: 1;
    &.empty-name { .c(@gray-400);}
  }
  .lesson-item-cover[data-error='true'] {
    .lesson-item-name {.c(#f00)}
  }
  .lesson-item-head {.flex;.mb(16);}

  .btn-lesson-remove {.ml(auto);}
  .btn-lesson-remove svg {.mr(8)}
  .btn-lesson-desc-add svg {.mr(8) }
  .description-group > * + * { .mt(12)}
  .description-group > label {.flex;}
  .description-group [text-input-alt] {flex: 1;}
  .description-group-title {.flex;.items-center;}
  .description-group-title .form-item-name {flex: 1;}
  .btn-desc-remove {.wh(30);.ml(8);.mt(8)}
  .btn-lesson-add {.br(50%);.wh(48);.p(0);.ml(auto);.mr(auto);.mt(40)}
  .badge-lesson-representative-sm {.p(4);.fs(12);.lh(12);font-weight: 500;.c(#2f50f8);.br(2);border: solid 1px #2f50f8;.mr(8)}
  .badge-lesson-representative {.p(8, 10);.br(4);.c(#2f50f8);border: solid 1px #2f50f8;display: inline-block;.bgc(@c-white);.fs(12);
    svg {fill: #2f50f8;.wh(12);.mr(6.4);.mt(auto);.mb(auto);}
  }

  .text-group [heading] { .mb(24)}
  .text-group [validation-errors] {.c(#f00);.mt(8)}
  .button-group {.flex;.flex-jc(flex-end);}
  .button-group {.mt(32);.mb(32);
    [basic-button] + [basic-button] {.ml(12);}
  }
  [section-container] + [section-container] {.mt(48);}
  [section-container]:last-child {.mt(48)}
  .be-replaced { .o(.5);border-bottom: solid 1px #2f50f8; cursor: grabbing;
    .drag-icon { cursor: grabbing;}
  }
  .lesson-item-group > * + * { .mt(24)}
  [heading] svg + h2 {.ml(5)}
  .dragging {cursor: grabbing;
    * {cursor: grabbing;}
  }

  .mobile-drag-help {.hide}

  @media (@tp-down) {
    .mt(24);
    .mobile-drag-help {.block}
    .numeric-group {flex-direction: column;}
    .numeric-group .form-item-name {.w(60);}
    .button-group {.hide}
  }

  .fade-enter-active, .fade-leave-active { transition: opacity 0.2s; transition-timing-function: ease-out;}
  .fade-enter, .fade-leave-to { opacity: 0;}

  * {
    &::selection {
      background: rgba(201, 222, 255, 0.5);
    }
    &::-moz-selection {
      background: rgba(201, 222, 255, 0.5);
    }
  }
}
</style>
