var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{attrs:{"coach-detail-class-edit":""},on:{"submit":function($event){$event.preventDefault();}}},[_c('validation-observer',{ref:"validator"},[_c('div',{staticClass:"button-group"},[_c('basic-button',{attrs:{"theme":"white"},on:{"click":_vm.onCancel}},[_vm._v("취소하기")]),_c('basic-button',{attrs:{"theme":"blue","type":"submit"},on:{"click":_vm.onSubmit}},[_vm._v("저장하기")])],1),_c('section-container',{staticClass:"text-group"},[_c('heading',{scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('svg-timer')]},proxy:true},{key:"title",fn:function(){return [_vm._v("수업 가능 시간")]},proxy:true}])}),_c('validation-provider',{attrs:{"rules":"required|length:10,1000","name":"lessonTime"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('memo-text-area',{attrs:{"has-error":!!errors.length,"max-length":1000,"min-length":10,"theme":"base-alt"},model:{value:(_vm.lessonTime),callback:function ($$v) {_vm.lessonTime=$$v},expression:"lessonTime"}}),_c('validation-errors',{attrs:{"errors":errors}})]}}])})],1),_c('section-container',{staticClass:"text-group"},[_c('heading',{scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('svg-recommend')]},proxy:true},{key:"title",fn:function(){return [_vm._v("이런 분들께 추천합니다")]},proxy:true}])}),_c('validation-provider',{attrs:{"rules":"required|length:10,1000","name":"lessonRecommendation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('memo-text-area',{attrs:{"has-error":!!errors.length,"max-length":1000,"min-length":10,"theme":"base-alt"},model:{value:(_vm.recommendation),callback:function ($$v) {_vm.recommendation=$$v},expression:"recommendation"}}),_c('validation-errors',{attrs:{"errors":errors}})]}}])})],1)],1),_c('section-container',{staticClass:"lesson-item-group"},[_c('heading',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("수업 내용")]},proxy:true},{key:"sub",fn:function(){return [_vm._v("최소 1개/최대 5개")]},proxy:true}])}),_c('coach-info-banner',{staticClass:"mobile-drag-help",attrs:{"theme":"grey"}},[_vm._v(" 꾹 눌러서 순서를 변경할 수 있습니다."),_c('br'),_vm._v(" 리스트의 첫 번째에 놓이면 대표 수업으로 지정합니다. ")]),_c('draggable',{class:{dragging: _vm.dragging},attrs:{"ghost-class":"be-replaced","handle":".drag-icon","draggable":".draggable-lesson-items"},on:{"start":_vm.onDragStart,"end":_vm.onDragEnd},model:{value:(_vm.lessonProducts),callback:function ($$v) {_vm.lessonProducts=$$v},expression:"lessonProducts"}},[_c('transition-group',{attrs:{"name":"fade"}},_vm._l((_vm.lessonProducts),function(lp){return _c('validation-observer',{key:lp.lessonProductId,ref:"lessonValidator",refInFor:true,staticClass:"draggable-lesson-items",attrs:{"data-lesson-product-id":lp.lessonProductId,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var dirty = ref.dirty;
return [_c('drawer',{attrs:{"value":_vm.openLessonProductId === lp.lessonProductId,"theme":"base-no-top-margin"},on:{"input":function (v) { return _vm.setOpenLessonProductId(lp.lessonProductId, v); }},scopedSlots:_vm._u([{key:"cover",fn:function(){return [_c('div',{staticClass:"lesson-item-cover",attrs:{"data-error":invalid,"data-modified":dirty}},[_c('img',{staticClass:"drag-icon",attrs:{"src":"/img/coaching/icon-list-drag.svg"}}),_c('h3',{directives:[{name:"show",rawName:"v-show",value:(!invalid),expression:"!invalid"}],staticClass:"lesson-item-name"},[_vm._v(_vm._s(lp.name))]),_c('h3',{directives:[{name:"show",rawName:"v-show",value:(invalid),expression:"invalid"}],staticClass:"lesson-item-name"},[_vm._v("내용을 확인해 주세요")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(lp.isRepresentative),expression:"lp.isRepresentative"}],staticClass:"badge-lesson-representative-sm"},[_vm._v("대표")])])]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"lesson-item-content"},[_c('div',{staticClass:"lesson-item-head"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(lp.isRepresentative),expression:"lp.isRepresentative"}],staticClass:"badge-lesson-representative"},[_c('svg-check'),_vm._v(" 대표수업 ")],1),_c('basic-button',{staticClass:"btn-lesson-remove",attrs:{"theme":"grey","disabled":_vm.lessonCountInsufficient,"size":"sm"},on:{"click":function () { return _vm.onLessonRemove(lp.lessonProductId); }}},[_c('svg-delete'),_vm._v(" 삭제 ")],1)],1),_c('validation-provider',{attrs:{"rules":"required|lengthMax:20","name":"lessonName","tag":"label"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var passed = ref.passed;
return [_c('span',{staticClass:"form-item-name"},[_vm._v("수업 제목")]),_c('text-input-alt',{attrs:{"errors":errors,"placeholder":"수업 제목 입력(최대 20자)"},scopedSlots:_vm._u([{key:"error",fn:function(){return [_c('validation-errors',{attrs:{"errors":errors,"passed":passed}})]},proxy:true}],null,true),model:{value:(lp.name),callback:function ($$v) {_vm.$set(lp, "name", $$v)},expression:"lp.name"}})]}}],null,true)}),_c('div',{staticClass:"numeric-group"},[_c('validation-provider',{attrs:{"rules":"required","name":"lessonTimeValue","tag":"label"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var passed = ref.passed;
return [_c('span',{staticClass:"form-item-name inline"},[_vm._v("소요시간")]),_c('text-input-alt',{attrs:{"type":"number","errors":errors,"min":"1","theme":"base-align-center"},scopedSlots:_vm._u([{key:"error",fn:function(){return [_c('validation-errors',{attrs:{"errors":errors,"passed":passed}})]},proxy:true}],null,true),model:{value:(lp.time.value),callback:function ($$v) {_vm.$set(lp.time, "value", $$v)},expression:"lp.time.value"}}),_c('span',[_vm._v("분")])]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":"lessonPrice","tag":"label"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var passed = ref.passed;
return [_c('span',{staticClass:"form-item-name inline"},[_vm._v("포인트")]),_c('text-input-alt',{attrs:{"type":"number","errors":errors,"min":"0","theme":"base-align-center"},scopedSlots:_vm._u([{key:"error",fn:function(){return [_c('validation-errors',{attrs:{"errors":errors,"passed":passed}})]},proxy:true}],null,true),model:{value:(lp.price),callback:function ($$v) {_vm.$set(lp, "price", $$v)},expression:"lp.price"}}),_c('span',[_vm._v("UP")])]}}],null,true)})],1),_c('div',{staticClass:"description-group"},[_c('div',{staticClass:"description-group-title"},[_c('span',{staticClass:"form-item-name"},[_vm._v("수업 설명")]),_c('basic-button',{staticClass:"btn-lesson-desc-add",attrs:{"size":"sm","theme":"grey","disabled":lp.description.length >= _vm.maxLessonDesc},on:{"click":function () { return _vm.onDescriptionAdd(lp.lessonProductId); }}},[_c('svg-add'),_vm._v(" 추가하기 ")],1)],1),_vm._l((lp.description),function(desc,i){return _c('validation-provider',{key:i,attrs:{"rules":"required|lengthMax:50","name":"lessonDescription","tag":"label","vid":("" + i)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var passed = ref.passed;
return [_c('text-input-alt',{attrs:{"errors":errors,"placeholder":"수업 설명을 입력하세요."},scopedSlots:_vm._u([{key:"error",fn:function(){return [_c('validation-errors',{attrs:{"errors":errors,"passed":passed}})]},proxy:true}],null,true),model:{value:(lp.description[i]),callback:function ($$v) {_vm.$set(lp.description, i, $$v)},expression:"lp.description[i]"}}),_c('basic-button',{staticClass:"btn-desc-remove",attrs:{"size":"sm","theme":"grey","disabled":lp.description.length <= _vm.minLessonDesc},on:{"click":function () { return _vm.onDescriptionRemove(lp.lessonProductId, i); }}},[_c('svg-minus-gray')],1)]}}],null,true)})})],2)],1)]},proxy:true}],null,true)})]}}],null,true)})}),1)],1),_c('basic-button',{staticClass:"btn-lesson-add",attrs:{"theme":"white","disabled":_vm.lessonCountExceed},on:{"click":_vm.onLessonAdd}},[_c('svg-plus')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }